import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, textBase } from '@styles/fonts'
import styled from 'styled-components'

export const SuburbSEOLinks = styled.div`
  background: ${COLORS.nature[95]};
  padding: 100px 0;
  margin-bottom: -32px;

  @media ${DEVICES.mobile} {
    padding: 72px 0;
  }
`

export const SuburbSEOLinksTitle = styled.h3`
  ${text2XL};
  margin-bottom: 32px;
`

export const SuburbSEOLinkGroup = styled.div`
  margin-bottom: 32px;
`

export const SuburbSEOLinkGroupTitle = styled.h4`
  ${textBase};
  margin-bottom: 24px;
  font-family: ${FONTS.medium};
  text-transform: none;
`

export const SuburbSEOLinkGroupItem = styled.div`
  margin-bottom: 6px;
  color: ${COLORS.navi};

  a {
    color: ${COLORS.navi};

    &:hover {
      color: ${COLORS.primary};
      text-decoration: underline;
    }
  }
`
