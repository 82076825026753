const IconListView = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path d='M1.5 1.5H6.7V6.7H1.5V1.5Z' fill='currentColor' />
      <path d='M9.3 1.5H14.5V6.7H9.3V1.5Z' fill='currentColor' />
      <path d='M1.5 9.3H6.7V14.5H1.5V9.3Z' fill='currentColor' />
      <path d='M9.3 9.3H14.5V14.5H9.3V9.3Z' fill='currentColor' />
    </svg>
  )
}

export default IconListView
