import Button from '@global/Button'
import { Router } from '@routes'
import { listSearchPageType } from '@lib/variables'
import * as S from './NoResults.styled'

const NoResults = ({ isAuction, pageType, onClearSearch }) => {
  const clearSearch = () => {
    if (onClearSearch) {
      onClearSearch()
    } else {
      const { state } = Router.router
      Router.pushRoute(state.asPath.split('?')[0])
    }
  }

  return isAuction ? (
    <S.NoResults>
      <h3>
        {'No '}
        {pageType === listSearchPageType.SEARCH_TYPE_AUCTION ? (
          'Auction'
        ) : pageType === listSearchPageType.SEARCH_TYPE_INSPECTION ? (
          'Open for Inspections'
        ) : (
          <>
            Sale by SET DATE<sup>&reg;</sup>
          </>
        )}
        {' Results'}
      </h3>
    </S.NoResults>
  ) : (
    <S.NoResults>
      <h3>
        Sorry, we couldn’t find any
        <br /> properties matching your search.
      </h3>
      <p>Try searching with another term, or extending your suburb reach.</p>
      <Button color='primary' border onClick={clearSearch}>
        Clear search
      </Button>
    </S.NoResults>
  )
}

export default NoResults
