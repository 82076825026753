import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import styled, { css } from 'styled-components'

export const Views = styled.div``

export const View = styled.div`
  margin-right: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }

  &:hover {
    cursor: pointer;
    color: ${COLORS.primary};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${COLORS.primary};
    `}

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`

export const Actions = styled.div``

export const FavouriteSearch = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;

  &:hover {
    color: ${COLORS.primary};
  }

  svg {
    margin-right: 6px;
  }
`

export const SearchResultsBar = styled.div`
  margin-bottom: 64px;
  color: ${COLORS.secondary};
  padding: 20px 0px;
  border-top: 1px solid ${COLORS.grey[20]};
  border-bottom: 1px solid ${COLORS.grey[20]};
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${COLORS.grey[20]};

      ${View} {
        pointer-events: none;
        color: ${COLORS.grey[20]};
      }
    `}

  @media ${DEVICES.mobile} {
    padding: 16px 10px;
    margin-bottom: 52px;

    ${View} {
      display: none;
      margin-right: 30px;

      &:nth-child(1),
      &:nth-child(2) {
        display: flex;
      }
    }
  }
`
