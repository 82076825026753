import Button from '@global/Button'
import { useState } from 'react'
import { listSearchPageType } from '@lib/variables'
import {
  getAuctionProperties,
  getInspectionProperties,
  getProperties,
} from '@lib/api-services/propertiesService'
import { getCookie } from '@lib/cookies'
import * as S from './LoadmoreInfo.styled'

const LoadmoreInfo = ({ queryParams, metaData, onSuccess, searchPageType }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const token = getCookie('token')

  const getListing = async () => {
    setIsLoading(true)
    let properties = {}

    const params = { ...queryParams, page: queryParams.page + 1 }

    switch (searchPageType) {
      case listSearchPageType.SEARCH_TYPE_PROPERTY: {
        properties = await getProperties(params, token || false)
        break
      }
      case listSearchPageType.SEARCH_TYPE_AUCTION: {
        params.has_auction = 'true'
        params.order_by = 'auction_date'
        properties = await getAuctionProperties(params, token || false)
        break
      }
      case listSearchPageType.SEARCH_TYPE_SALEDATE: {
        params.has_salebySetDate = 'true'
        params.order_by = 'salebySetDate'
        properties = await getProperties(params, token || false)
        break
      }
      case listSearchPageType.SEARCH_TYPE_INSPECTION: {
        properties = await getInspectionProperties(
          { ...params },
          token || false
        )
        properties.results = properties.results.map((it) => it.listing)
        break
      }
    }

    onSuccess && onSuccess(properties.results, params.page)
    if (!properties.next) {
      setIsFinished(true)
    }
    setIsLoading(false)
  }

  return metaData.total ? (
    <S.ResultsMeta>
      <S.ResultsMetaInfo>
        Showing {metaData.end} of {metaData.total} results
        <S.ResultsMetaBar width={(metaData.end / metaData.total) * 100} />
      </S.ResultsMetaInfo>
      {!isFinished && (
        <Button color='secondary' onClick={getListing} disabled={isLoading}>
          Load More
        </Button>
      )}
    </S.ResultsMeta>
  ) : null
}

export default LoadmoreInfo
