import ListingCard from '@global/ListingCard'
import { Row, Col } from '@styles/global.styled'
import Head from 'next/head'
import { InView } from 'react-intersection-observer'
import React, { useState } from 'react'

const ListingCardGrid = ({ items }) => {
  const [belowFold, setBelowFold] = useState(false)
  const onChangeView = (inView) => inView && setBelowFold(true)

  return (
    <Row gutter={20}>
      {items.map((item, i) => {
        const data = ListingCard.ApiDataToProps(item)
        return i < 4 ? (
          <React.Fragment key={`quick-view-${i}`}>
            {i < 2 && (
              <Head>
                {data.dataSource.image_url && (
                  <link
                    rel='preload'
                    as='image'
                    href={data.dataSource.image_url}
                  />
                )}
              </Head>
            )}
            <Col md={6} key={i} flex>
              <ListingCard quickload={i < 2} {...data} results />
            </Col>
          </React.Fragment>
        ) : belowFold ? (
          <Col md={6} key={i} flex>
            <ListingCard {...data} results />
          </Col>
        ) : (
          <InView as='div' key={`more-data-${i}`} onChange={onChangeView} />
        )
      })}
    </Row>
  )
}

export default ListingCardGrid
