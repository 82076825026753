import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import styled, { css } from 'styled-components'

export const SaveSearch = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;
  margin-left: 24px;

  &:hover {
    color: ${COLORS.primary};
  }

  svg {
    margin-right: 6px;
  }

  ${({ saved }) =>
    saved &&
    css`
      cursor: default;

      &:hover {
        color: ${COLORS.secondary};
      }
    `}

  @media ${DEVICES.mobile} {
    span {
      display: none;
    }

    svg {
      margin-right: 0;
    }
  }
`
