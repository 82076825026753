const IconAuctions = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='currentColor'
      {...props}
    >
      <path
        d='M7.21411 14.6312H7.0531C7.06945 14.5828 7.06945 14.5344 7.06945 14.4866C7.06945 14.1161 6.7795 13.8262 6.40905 13.8262H1.73972C1.36927 13.8262 1.07932 14.1161 1.07932 14.4866C1.07932 14.535 1.07932 14.5834 1.09567 14.6312H0.934662C0.693146 14.6312 0.500061 14.8243 0.500061 15.0658C0.500061 15.3074 0.693146 15.5004 0.934662 15.5004H7.2141C7.45561 15.5004 7.6487 15.3074 7.6487 15.0658C7.66505 14.8243 7.45563 14.6312 7.21411 14.6312Z'
        fill='currentColor'
      />
      <path
        d='M13.9609 11.9336L9.03368 6.4756L10.0161 5.59004C10.0803 5.52589 10.113 5.44538 10.1287 5.36488C10.1287 5.28438 10.0966 5.18751 10.0482 5.12337L7.63301 2.43464C7.52043 2.30571 7.31099 2.28998 7.18205 2.40256L3.99387 5.26808C3.92972 5.33223 3.89701 5.41274 3.88129 5.49324C3.88129 5.57374 3.91336 5.67061 3.96179 5.73476L6.37696 8.42348C6.45746 8.50399 6.55433 8.53607 6.65055 8.53607C6.7147 8.53607 6.77948 8.50399 6.82792 8.45556L7.81035 7.57L12.7212 13.028C12.8986 13.2374 13.1558 13.3179 13.4137 13.2858C13.5747 13.2695 13.7357 13.2053 13.8646 13.0764C14.2181 12.7871 14.2509 12.2719 13.9609 11.9336Z'
        fill='currentColor'
      />
      <path
        d='M3.60778 6.2501C3.30211 5.91171 2.80272 5.896 2.46434 6.18594L2.22282 6.4111C2.06181 6.55576 1.96495 6.74948 1.94923 6.97464C1.93288 7.18408 2.01339 7.39353 2.15868 7.55454L4.44487 10.0987C4.62224 10.3081 4.87948 10.3886 5.13735 10.3566C5.29836 10.3402 5.45937 10.276 5.58831 10.1471L5.82983 9.92195C5.99084 9.77729 6.0877 9.58357 6.10342 9.35841C6.11977 9.14897 6.03926 8.93952 5.89397 8.77851L3.60778 6.2501Z'
        fill='currentColor'
      />
      <path
        d='M10.4828 4.62427C10.6275 4.78528 10.8212 4.88214 11.0463 4.89786H11.1753C11.3363 4.88151 11.4973 4.801 11.6262 4.68842L11.8677 4.46326C12.2061 4.15759 12.2218 3.6582 11.9319 3.31982L9.6457 0.775681C9.50104 0.614669 9.30732 0.517805 9.08216 0.50209C8.87272 0.485738 8.66327 0.566244 8.50226 0.711534L8.26075 0.936692C7.92236 1.24236 7.90665 1.74175 8.19659 2.08013L10.4828 4.62427Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconAuctions
