import Select from '@global/FormInput/Select'
import { arrangeBy, propertyCategory } from '@lib/searchFields'
import { titleCase } from '@lib/helpers'
import { PROPERTY_CLASS } from '@constants/property'
import { listSearchPageType } from '@lib/variables'
import format from 'date-fns/format'
import * as S from './OverviewHeading.styled'

const buildLocationString = ({ address_region, address_suburb }) => {
  let location = ''
  if (address_region || address_suburb) {
    if (address_suburb) {
      location = address_suburb
        .split(',')
        .map((it) => titleCase(it))
        .join(', ')
    } else if (address_region) {
      location = address_region
        .split(',')
        .map((it) => titleCase(it))
        .join(', ')
    }
  }

  return location
}

const pluralize = (words, noPluralList = []) => {
  return words.map(word => {
    // If the word is in the list of words that don't need pluralization, return it as is
    if (noPluralList.includes(word)) {
      return word
    }

    // Split the word if it contains a slash and pluralize each part
    if (word.includes('/')) {
      return word.split('/').map(part => pluralize([part.trim()], noPluralList)[0]).join('/ ')
    }

    // Apply the pluralization rules
    if (word.endsWith('s') || word.endsWith('sh') || word.endsWith('ch') || word.endsWith('x') || word.endsWith('z')) {
      return `${word}es`
    } else if (word.endsWith('y') && !['a', 'e', 'i', 'o', 'u'].includes(word[word.length - 2])) {
      return `${word.substring(0, word.length - 1)}ies`
    } else {
      return `${word}s`
    }
  })
}


const getLabelsByValues = (property_class, categories) => {
  // Check if the property_class is either 'residential' or 'commercial'
  if (!['residential', 'commercial'].includes(property_class)) {
    return []
  }

  // Retrieve the options for the given property class
  const options = propertyCategory[property_class].options

  // Map the categories to their corresponding labels
  return options
    .filter(option => option.value && categories.includes(option.value))
    .map(option => option.label)
}

const buildSearchString = ({
  bedrooms__gte,
  categories,
  address_region,
  address_suburb,
  listingType,
  date_from,
  date_to,
  location,
  status,
  property_class,
}) => {

  let searchString = ''
  if (bedrooms__gte) {
    searchString += `${bedrooms__gte} Bedroom `
  }

  if (categories) {
    const noPluralList = ['Land', 'Rural', 'Acreage'];
    const labelsCategories = getLabelsByValues(property_class, categories)
    const categoriesList = pluralize(labelsCategories, noPluralList).join(', ')
    searchString += `${categoriesList} For ${listingType}`
  }
  if (!bedrooms__gte && !categories) {
    if (address_region || address_suburb) {
      searchString +=
        status === 'sold'
          ? `Properties Sold in ${location}`
          : `Real Estate For ${listingType} in ${location}`
    } else {
      searchString +=
        status === 'sold'
          ? 'Properties Sold'
          : `${
              property_class === 'commercial' ? 'Commercial ' : ''
            }Properties For ${listingType}`
    }
  } else {
    if (location) {
      searchString += `<br />${location}`
    }
  }

  // Auction / Inspection
  if (date_from && date_to) {
    searchString = `${format(new Date(date_from), 'E do MMM yyyy')} - ${format(
      new Date(date_to),
      'E do MMM yyyy'
    )}`
  }

  return searchString
}

const OverviewHeading = ({
  metaData,
  orderBy,
  onOrderChange,
  queryParams,
  pageType,
}) => {
  const { listing_type, property_class } = queryParams
  let listingType = titleCase(listing_type)
  if (property_class === PROPERTY_CLASS.COMMERCIAL) {
    listingType === 'Lease'
  }

  const location = buildLocationString({ ...queryParams })
  const searchString = buildSearchString({
    ...queryParams,
    listingType,
    location,
  })

  return (
    <S.OverviewHeading disabled={metaData.total === 0}>
      <h1>
        {metaData.total === 0 ? (
          `0 results ${location ? `for "${location}"` : ''}`
        ) : (
          <>
            {(pageType === listSearchPageType.SEARCH_TYPE_AUCTION ||
              pageType === listSearchPageType.SEARCH_TYPE_INSPECTION) && (
              <span>
                {pageType === listSearchPageType.SEARCH_TYPE_AUCTION
                  ? 'Auction Times'
                  : pageType === listSearchPageType.SEARCH_TYPE_INSPECTION
                  ? 'Open for Inspection Times'
                  : null}
                <br />
              </span>
            )}
            <span dangerouslySetInnerHTML={{ __html: searchString }} />
          </>
        )}
      </h1>
      {orderBy && pageType === listSearchPageType.SEARCH_TYPE_PROPERTY && (
        <S.SortBy>
          Sort by
          <Select
            defaultValue={
              arrangeBy.options.find((it) => it.value === orderBy) ?? null
            }
            options={arrangeBy.options}
            onChange={(option) => onOrderChange(option.value)}
          />
        </S.SortBy>
      )}
    </S.OverviewHeading>
  )
}

export default OverviewHeading
