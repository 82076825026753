import IconHeart from '@icons/Heart'
import IconHeartFilled from '@icons/HeartFilled'
import { Router, Link } from '@routes'
import { cleanSaveSearchParams, isSearchSaved } from '@lib/helpers'
import { createAlert, userAlerts } from '@lib/api-services/authService'
import { toast } from 'react-toastify'
import { UserContext } from '@context/UserContext'
import { useContext } from 'react'
import IconAlert from '@icons/Alert'
import { useFormikContext } from 'formik'
import * as S from './SaveSearch.styled'
import { COLORS } from '@styles/colors'

const SaveSearch = ({ setFieldValue, isAlert }) => {
  const formikContext = useFormikContext()
  const { token, alerts, setAlerts, toggleFavouriteModal } =
    useContext(UserContext)

  const saveSearch = async () => {
    const nValues = cleanSaveSearchParams(formikContext?.values ?? {})

    // Setup alert name + criteria
    let params = {
      descriptive_name: 'Saved search',
      frequency: isAlert ? 7 : 0, // Default to save search only
      criteria: {
        ...nValues,
        slug: Router.router.query.slug,
      },
    }

    if (!token) {
      localStorage.setItem('saveSearchParams', JSON.stringify(params))
      toggleFavouriteModal()
      return
    }

    // Create the saved search
    const alertResponse = await createAlert(token, params)

    if (!alertResponse.error) {
      // Successful submission
      setFieldValue && setFieldValue('save_search', true)
      const alertResponse = await userAlerts(token)
      setAlerts(alertResponse.results)
      toast.success(
        <span>
          Your search has been saved. View your saved searches in{' '}
          <Link route='/my-account' passHref>
            My Account
          </Link>
        </span>
      )
    } else {
      toast.success('Your search has been removed!')
    }
  }

  return isSearchSaved(
    { ...formikContext?.values, slug: Router?.router?.query?.slug },
    alerts,
    isAlert
  ) ? (
    <S.SaveSearch saved>
      {isAlert ? (
        <IconAlert style={{ color: COLORS.primary }} />
      ) : (
        <IconHeartFilled />
      )}
      <span>{isAlert ? 'Alert Saved' : 'Search Saved'}</span>
    </S.SaveSearch>
  ) : (
    <S.SaveSearch onClick={saveSearch}>
      {isAlert ? <IconAlert /> : <IconHeart />}
      <span>{isAlert ? 'Make an alert' : 'Favourite Search'}</span>
    </S.SaveSearch>
  )
}

export default SaveSearch
