const IconAlert = (props) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0795 7.03846V10.8846L14.618 12.4231V13.1923H2.3103V12.4231L3.84876 10.8846V7.03846C3.84876 4.66923 5.1026 2.7 7.31029 2.17692V1.65385C7.31029 1.01538 7.82567 0.5 8.46413 0.5C9.10259 0.5 9.61798 1.01538 9.61798 1.65385V2.17692C11.818 2.7 13.0795 4.67692 13.0795 7.03846ZM10.0026 13.9615C10.0026 14.8077 9.31029 15.5 8.46413 15.5C7.61029 15.5 6.92568 14.8077 6.92568 13.9615H10.0026ZM8.20013 7.91525C8.20928 7.98842 8.2916 8.06159 8.37391 8.06159H8.6666C8.74891 8.06159 8.83123 7.98842 8.84038 7.91525L9.19708 5.34515V5.08296C9.19708 4.9915 9.11477 4.90918 9.0233 4.90918H8.01721C7.92575 4.90918 7.84343 4.9915 7.84343 5.08296V5.36344L8.20013 7.91525ZM8.51111 9.13171C8.10867 9.13171 7.77026 9.47926 7.77026 9.89085C7.77026 10.3024 8.10867 10.6408 8.51111 10.6408C8.93184 10.6408 9.27025 10.3024 9.27025 9.89085C9.27025 9.47926 8.93184 9.13171 8.51111 9.13171Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconAlert
