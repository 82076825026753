import { arrangeBy } from '@lib/searchFields'
import Select from '@global/FormInput/Select'
import * as S from './SearchResultsCount.styled'

const SearchResultCount = ({
  searchView,
  metaData,
  orderBy,
  onOrderChange,
}) => {
  return metaData.total ? (
    <S.SearchResultCount>
      {searchView !== 'map' && <span>1-{metaData.end} of&nbsp;</span>}
      <span>{metaData.total} results</span>

      <Select
        defaultValue={
          arrangeBy.options.find((it) => it.value === orderBy) ?? null
        }
        options={arrangeBy.options}
        onChange={(option) => onOrderChange(option.value)}
      />
    </S.SearchResultCount>
  ) : null
}

export default SearchResultCount
