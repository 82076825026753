import { channelNames } from '@lib/variables'
import { isExternalURL } from '@lib/helpers'
import { Link } from '@routes'
import { Col, Container, Row } from '@styles/global.styled'
import * as S from './SuburbSEOLinks.styled'

const SuburbSEOLinks = ({ suburb, state, slug, linkGroups }) => {
  const getRouteName = (link) => {
    return link.criteria && link.criteria.categories
      ? 'suburbProperties'
      : link.criteria && (link.criteria.bedrooms || link.criteria.bedrooms__gte)
      ? 'suburbPropertiesBedrooms'
      : 'suburbProperties'
  }

  const getQueryParams = ({ link, slug, channel, state }) => {
    let params = {
      suburb: slug,
      state: state.toLowerCase(),
      channel: channel,
    }
    if (link && link.criteria && link.criteria.categories) {
      params.categories = link.criteria.categories
    }
    if (link && link.criteria && link.criteria.bedrooms) {
      params.bedrooms = link.criteria.bedrooms
    }
    return params
  }

  return suburb ? (
    <S.SuburbSEOLinks>
      <Container>
        <S.SuburbSEOLinksTitle>{suburb} quick links</S.SuburbSEOLinksTitle>
        {linkGroups?.length > 0 && (
          <Row gutter={15}>
            {linkGroups.map((group, index) => (
              <Col md={6} lg={3} key={index}>
                <S.SuburbSEOLinkGroup>
                  <S.SuburbSEOLinkGroupTitle>
                    {group.title}
                  </S.SuburbSEOLinkGroupTitle>
                  {group.links?.length &&
                    group.links.map((link, index) => (
                      <S.SuburbSEOLinkGroupItem
                        key={`suburb-seo-link-${index}`}
                      >
                        {link.title}
                        {link.type === 'sale' || link.type === 'both' ? (
                          <>
                            &nbsp;-&nbsp;
                            <Link
                              route={getRouteName(link)}
                              params={getQueryParams({
                                slug: slug,
                                state: state.toLowerCase(),
                                channel:
                                  link.type === 'sale' || link.type === 'both'
                                    ? channelNames.sale
                                    : channelNames.rent,
                                link: link,
                              })}
                              passHref
                              target={
                                isExternalURL(getRouteName(link))
                                  ? '_blank'
                                  : '_self'
                              }
                              key={`group-link-item-${index}`}
                            >
                              for Sale
                            </Link>
                          </>
                        ) : null}
                        {link.type === 'lease' || link.type === 'both' ? (
                          <>
                            &nbsp;-&nbsp;
                            <Link
                              route={getRouteName(link)}
                              params={getQueryParams({
                                slug: slug,
                                state: state.toLowerCase(),
                                channel: channelNames.rent,
                                link: link,
                              })}
                              passHref
                              target={
                                isExternalURL(getRouteName(link))
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              for Rent
                            </Link>
                          </>
                        ) : null}
                      </S.SuburbSEOLinkGroupItem>
                    ))}
                </S.SuburbSEOLinkGroup>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </S.SuburbSEOLinks>
  ) : null
}

SuburbSEOLinks.defaultProps = {
  suburb: 'Coburg',
  slug: 'coburg',
  linkGroups: [
    {
      title: 'Residential Property',
      links: [
        {
          title: 'Acreage Property',
          type: 'sale',
          criteria: {
            categories: 'acreages',
            property_class: {
              sale: 'residential',
            },
          },
        },
        {
          title: 'Apartments',
          type: 'both',
          criteria: {
            categories: 'apartments',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Apartment Blocks',
          type: 'sale',
          criteria: {
            categories: 'apartment-blocks',
          },
        },
        {
          title: 'Duplex',
          type: 'both',
          criteria: {
            categories: 'duplexes',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'House',
          type: 'both',
          criteria: {
            categories: 'houses',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'New Developments',
          type: 'sale',
          criteria: {
            categories: 'new-developments',
            property_class: {
              sale: 'residential',
            },
          },
        },
        {
          title: 'Studio Apartment',
          type: 'both',
          criteria: {
            categories: 'studio',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Townhouses',
          type: 'both',
          criteria: {
            categories: 'townhouses',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Units',
          type: 'both',
          criteria: {
            categories: 'units',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: 'Vacant Land',
          type: 'sale',
          criteria: {
            categories: 'land',
            property_class: {
              sale: 'land',
            },
          },
        },
        {
          title: 'Villas',
          type: 'both',
          criteria: {
            categories: 'villas',
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
      ],
    },
    {
      title: 'Find Properties by Bedrooms',
      links: [
        {
          title: 'All bedrooms',
          type: 'both',
          criteria: {
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '1 bedroom',
          type: 'both',
          criteria: {
            bedrooms: 1,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '2 bedrooms',
          type: 'both',
          criteria: {
            bedrooms: 2,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '3 bedrooms',
          type: 'both',
          criteria: {
            bedrooms: 3,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '4 bedrooms',
          type: 'both',
          criteria: {
            bedrooms: 4,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
        {
          title: '5+ bedrooms',
          type: 'both',
          criteria: {
            bedrooms: 5,
            property_class: {
              sale: 'residential',
              lease: 'rental',
            },
          },
        },
      ],
    },
  ],
}

export default SuburbSEOLinks
