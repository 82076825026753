import IconListView from '@icons/ListView'
import IconCalendar from '@icons/Clendar'
import IconAuctions from '@icons/Auctions'
import IconPadlock from '@icons/Padlock'
import IconMapPoint from '@icons/MapPoint'
import useWindowSize from 'hooks/useWindowSize'
import SaveSearch from './SaveSearch'
import { listSearchPageType } from '@lib/variables'
import * as S from './SearchResultsBar.styled'

const Views = [
  {
    label: 'Grid View',
    labelMoblie: 'Grid',
    value: 'location',
    pageType: listSearchPageType.SEARCH_TYPE_PROPERTY,
    icon: <IconListView />,
  },
  {
    label: 'Map View',
    labelMoblie: 'Map',
    value: 'map',
    pageType: listSearchPageType.SEARCH_TYPE_PROPERTY,
    icon: <IconMapPoint />,
  },
  {
    label: 'Inspections',
    value: 'inspections',
    pageType: listSearchPageType.SEARCH_TYPE_INSPECTION,
    icon: <IconCalendar />,
  },
  {
    label: 'Auctions',
    value: 'auctions',
    pageType: listSearchPageType.SEARCH_TYPE_AUCTION,
    icon: <IconAuctions />,
  },
  {
    label: 'Off Market',
    value: 'exclusivepreview',
    pageType: listSearchPageType.SEARCH_TYPE_PROPERTY,
    icon: <IconPadlock />,
  },
]

const SearchResultsBar = ({
  onViewChange,
  view = 'location',
  disabled,
  pageType,
  searchFormValues,
  listingType,
}) => {
  const windowSize = useWindowSize()

  return (
    <S.SearchResultsBar disabled={disabled}>
      <S.Views>
        {Views.map((it) => (
          <S.View
            key={it.value}
            onClick={() => onViewChange(it.value)}
            active={
              pageType === listSearchPageType.SEARCH_TYPE_PROPERTY
                ? view === it.value
                : it.pageType === pageType
            }
            hidden={it.value === 'auctions' && listingType === 'lease'} // Hide auctions on lease listing results
          >
            {it.icon}
            <span>
              {windowSize.width < 1024 && it.labelMoblie
                ? it.labelMoblie
                : it.label}
            </span>
          </S.View>
        ))}
      </S.Views>
      <S.Actions>
        <SaveSearch values={searchFormValues} />
        <SaveSearch isAlert values={searchFormValues} />
      </S.Actions>
    </S.SearchResultsBar>
  )
}

export default SearchResultsBar
