import ListingCardGrid from './ListingCardGrid'
import NoResults from './NoResults'
import dynamic from 'next/dynamic'

const SearchResultsMap = dynamic(() => import('./SearchResultsMap'))

const SearchResults = ({
  items,
  searchView,
  mapResults,
  queryParams,
  totalItems,
  onClearSearch,
}) => {
  return (
    <div>
      {items?.length > 0 ? (
        items && searchView === 'map' ? (
          <SearchResultsMap
            properties={mapResults.results}
            queryParams={queryParams}
            listingCount={mapResults.count}
            view={searchView}
            totalItems={totalItems}
          />
        ) : items ? (
          <ListingCardGrid
            items={items}
            searchView={searchView}
            queryParams={queryParams}
          />
        ) : null
      ) : (
        <NoResults queryParams={queryParams} onClearSearch={onClearSearch} />
      )}
    </div>
  )
}

SearchResults.defaultProps = {
  items: [],
  metaData: {},
}

export default SearchResults
