const IconMapPoint = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.00019 1C5.33177 1 3.1875 3.14343 3.1875 5.81268C3.1875 8.4811 8.00019 15 8.00019 15C8.00019 15 12.8129 8.4811 12.8129 5.81268C12.8129 3.14343 10.6686 1 8.00019 1ZM8.00019 7.99958C6.77549 7.99958 5.81245 7.03738 5.81245 5.81184C5.81245 4.58715 6.77465 3.62411 8.00019 3.62411C9.22488 3.62411 10.1879 4.58631 10.1879 5.81184C10.1879 7.03736 9.2249 7.99958 8.00019 7.99958Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconMapPoint
