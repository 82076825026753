const IconHeart = (props) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask id='path-1-inside-1_4169_9040' fill='white'>
        <path d='M4.71911 1C3.63757 1 2.55813 1.41713 1.7346 2.245C0.0874033 3.90073 0.0895316 6.58043 1.7346 8.23697L8.1073 14.6608C8.21034 14.7631 8.3489 14.8214 8.49387 14.8214C8.63955 14.8214 8.77812 14.7631 8.88044 14.6608C11.008 12.5226 13.1369 10.3865 15.2646 8.24825C16.9118 6.59251 16.9118 3.90644 15.2646 2.25064C13.6174 0.594908 10.937 0.594908 9.28975 2.25064L8.50524 3.04652L7.70936 2.24496C6.88577 1.4171 5.80079 1 4.71911 1ZM4.71911 2.08013C5.51712 2.08013 6.31941 2.39208 6.93631 3.01245L8.11876 4.20058C8.2218 4.30291 8.36036 4.36118 8.50533 4.36118C8.65101 4.36118 8.78958 4.30291 8.8919 4.20058L10.063 3.01813C11.2966 1.77811 13.2578 1.77811 14.4916 3.01813C15.7252 4.25814 15.7252 6.23514 14.4916 7.47508C12.4927 9.48399 10.493 11.4978 8.49396 13.5067L2.50783 7.46926C1.27493 6.22783 1.27422 4.25225 2.50783 3.01231C3.12464 2.39193 3.92114 2.08013 4.71911 2.08013Z' />
      </mask>
      <path
        d='M4.71911 1C3.63757 1 2.55813 1.41713 1.7346 2.245C0.0874033 3.90073 0.0895316 6.58043 1.7346 8.23697L8.1073 14.6608C8.21034 14.7631 8.3489 14.8214 8.49387 14.8214C8.63955 14.8214 8.77812 14.7631 8.88044 14.6608C11.008 12.5226 13.1369 10.3865 15.2646 8.24825C16.9118 6.59251 16.9118 3.90644 15.2646 2.25064C13.6174 0.594908 10.937 0.594908 9.28975 2.25064L8.50524 3.04652L7.70936 2.24496C6.88577 1.4171 5.80079 1 4.71911 1ZM4.71911 2.08013C5.51712 2.08013 6.31941 2.39208 6.93631 3.01245L8.11876 4.20058C8.2218 4.30291 8.36036 4.36118 8.50533 4.36118C8.65101 4.36118 8.78958 4.30291 8.8919 4.20058L10.063 3.01813C11.2966 1.77811 13.2578 1.77811 14.4916 3.01813C15.7252 4.25814 15.7252 6.23514 14.4916 7.47508C12.4927 9.48399 10.493 11.4978 8.49396 13.5067L2.50783 7.46926C1.27493 6.22783 1.27422 4.25225 2.50783 3.01231C3.12464 2.39193 3.92114 2.08013 4.71911 2.08013Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconHeart
