const IconCalendar = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 2.90039H5V1.90039C5 1.62413 4.77626 1.40039 4.5 1.40039C4.22374 1.40039 4 1.62413 4 1.90039V2.90039H3C2.60187 2.90039 2.22062 3.05852 1.93938 3.33977C1.65813 3.62101 1.5 4.00226 1.5 4.40039V12.4004C1.5 12.7985 1.65812 13.1798 1.93938 13.461C2.22062 13.7423 2.60187 13.9004 3 13.9004H13C13.3981 13.9004 13.7794 13.7423 14.0606 13.461C14.3419 13.1798 14.5 12.7985 14.5 12.4004V4.40039C14.5 4.00226 14.3419 3.62101 14.0606 3.33977C13.7794 3.05852 13.3981 2.90039 13 2.90039H12V1.90039C12 1.62413 11.7763 1.40039 11.5 1.40039C11.2237 1.40039 11 1.62413 11 1.90039V2.90039ZM13.5 6.90039V12.4004C13.5 12.5329 13.4475 12.6604 13.3538 12.7541C13.26 12.8479 13.1325 12.9004 13 12.9004H3.00001C2.86751 12.9004 2.74001 12.8479 2.64626 12.7541C2.55251 12.6604 2.50001 12.5329 2.50001 12.4004V6.90039H13.5ZM4 3.90039H3C2.8675 3.90039 2.74 3.95289 2.64626 4.04664C2.55251 4.14039 2.50001 4.26789 2.50001 4.40038V5.90038H13.5V4.40038C13.5 4.26789 13.4475 4.14038 13.3538 4.04664C13.26 3.95289 13.1325 3.90039 13 3.90039H12V4.40039C12 4.67665 11.7763 4.90039 11.5 4.90039C11.2238 4.90039 11 4.67665 11 4.40039V3.90039H5.00001V4.40039C5.00001 4.67665 4.77627 4.90039 4.50001 4.90039C4.22376 4.90039 4.00001 4.67665 4.00001 4.40039L4 3.90039Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default IconCalendar
