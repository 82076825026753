import addHours from 'date-fns/addHours'
import startOfDay from 'date-fns/startOfDay'
import { itemShowPerPage, listSearchPageType } from '../variables'
import {
  LISTING_TYPE,
  PROPERTY_CLASS,
  LISTING_STATUS,
  PROPERTY_RESULTS_PATH,
} from '@constants/property'
import { titleCase } from '@lib/helpers'

// sortAuctions()
// --------------
// sort auction listings
export const sortAuctions = (listings = []) => {
  let data = []

  // return if no listings
  if (!listings.length) return data

  // loop through listings
  listings.map((listing) => {
    if (!listing.auction_date) return

    // get date group heading
    const auctionDate = addHours(startOfDay(new Date(listing.auction_date)), 10)
    const auctionDateString = auctionDate.toDateString()

    // set date group heading if it doesn't exist
    if (!data[auctionDateString]) {
      data[auctionDateString] = {
        time: auctionDate,
        items: [],
        type: listSearchPageType.SEARCH_TYPE_AUCTION,
      }
    }

    // add listing to date group if it doesn't exist
    if (!data[auctionDateString].items.find((s) => s.id == listing.id)) {
      data[auctionDateString].items.push(listing)
    }
  })

  // sort group in date order
  data = Object.keys(data).map((key) => data[key])
  data = data.sort((a, b) => a.time - b.time)

  return data
}

// sortInspections()
// -----------------
// sort inspection listings
export const sortInspections = (listings = []) => {
  let data = []

  // return if no listings
  if (!listings.length) return data

  // loop through listings
  listings.map((listing) => {
    if (!listing.inspection_time) return

    const eventDate = addHours(
      startOfDay(new Date(listing.inspection_time)),
      10
    )
    const eventDateString = eventDate.toDateString()
    // get date group heading
    if (!data[eventDateString]) {
      data[eventDateString] = {
        time: eventDate,
        items: [],
        type: listSearchPageType.SEARCH_TYPE_INSPECTION,
      }
    }

    // add listing to date group
    if (!data[eventDateString].items.find((s) => s.id == listing.listing.id)) {
      data[eventDateString].items.push(listing.listing)
    }
  })

  //sort group in date order
  data = Object.keys(data).map((key) => data[key])
  data = data.sort((a, b) => a.time - b.time)

  return data
}

// sortSetDate()
// -----------------
// sort sale by set date listings
export const sortSetDate = (listings = []) => {
  let data = []

  // return if no listings
  if (!listings.length) return data

  // loop through listings
  listings.map((listing) => {
    if (!listing.salebySetDate) return

    // get date group heading
    const setDate = addHours(startOfDay(new Date(listing.salebySetDate)), 10)
    const setDateString = setDate.toDateString()

    // set date group heading if it doesn't exist
    if (!data[setDateString]) {
      data[setDateString] = {
        time: setDate,
        items: [],
        type: listSearchPageType.SEARCH_TYPE_SALEDATE,
      }
    }

    // add listing to date group
    if (!data[setDateString].items.find((s) => s.id == listing.id)) {
      data[setDateString].items.push(listing)
    }
  })

  // sort group in date order
  data = Object.keys(data).map((key) => data[key])
  data = data.sort((a, b) => a.time - b.time)

  return data
}

export const getListingSearchBreadcrumbs = (queryParams) => {
  const {
    listing_type = 'sale',
    categories,
    property_class,
    address_region,
    address_suburb,
    bedrooms__gte,
    status,
  } = queryParams

  let breadcrumbs = []
  if (listing_type === LISTING_TYPE.SALE) {
    if (status === 'sold') {
      breadcrumbs.push({
        label: 'Sold',
        url: PROPERTY_RESULTS_PATH.sold,
      })
    } else {
      breadcrumbs.push({
        label: 'For Sale',
        url: PROPERTY_RESULTS_PATH.sale,
      })
    }

    if (property_class === PROPERTY_CLASS.COMMERCIAL) {
      breadcrumbs.push({
        label: 'Commercial',
        url: PROPERTY_RESULTS_PATH.commercialSale,
      })
    }
  } else {
    if (property_class === PROPERTY_CLASS.COMMERCIAL) {
      breadcrumbs.push({
        label: 'Lease',
        url: PROPERTY_RESULTS_PATH.lease,
      })
    } else {
      breadcrumbs.push({
        label: 'For Rent',
        url: PROPERTY_RESULTS_PATH.lease,
      })
    }
  }

  if (address_region && address_region.split(',').length === 1) {
    breadcrumbs.push({
      label: titleCase(address_region),
      url: PROPERTY_RESULTS_PATH.sale,
    })
  }

  if (address_suburb && address_suburb.split(',').length === 1) {
    breadcrumbs.push({
      label: titleCase(address_suburb),
      url: PROPERTY_RESULTS_PATH.sale,
    })
  }

  if (categories && categories.split(',').length === 1) {
    breadcrumbs.push({
      label: categories,
      url: PROPERTY_RESULTS_PATH.sale,
    })
  }

  if (bedrooms__gte) {
    breadcrumbs.push({
      label: `${bedrooms__gte} ${bedrooms__gte > 1 ? 'bedrooms' : 'bedroom'}`,
      url: PROPERTY_RESULTS_PATH.sale,
    })
  }

  return breadcrumbs
}

export const getPropertyResultsMetaData = (queryParams, total, agent) => {
  const { page = 1, per_page = itemShowPerPage.ListingSearchPage } = queryParams
  let meta_type = ''
  let meta_other = ''
  let meta_locations = []

  const meta_from = page * per_page - per_page + 1
  const meta_to = page * per_page >= total ? total : page * per_page

  if (queryParams) {
    queryParams.categories
      ? (meta_type += queryParams.categories)
      : (meta_type += 'Properties')

    if (queryParams.listing_type) {
      meta_type = `${meta_type}${
        queryParams.status &&
        queryParams.status.toLowerCase() !== LISTING_STATUS.LEASED &&
        queryParams.status.toLowerCase() !== LISTING_STATUS.SOLD
          ? ' for '
          : ' '
      }${
        queryParams.listing_type === 'lease' &&
        queryParams.status &&
        queryParams.status.toLowerCase() === LISTING_STATUS.LEASED
          ? 'Leased'
          : queryParams.is_inspection === true
          ? 'Inspections'
          : queryParams.listing_type === 'sale' &&
            queryParams.status &&
            queryParams.status.toLowerCase() === LISTING_STATUS.SOLD
          ? 'Sold'
          : titleCase(queryParams.listing_type)
      }`
    } else {
      meta_type = null
    }

    meta_other = queryParams.bedrooms__gte
      ? `with ${queryParams.bedrooms__gte}+ bedrooms`
      : ''
    meta_locations = []
    if (queryParams && queryParams.address_suburb) {
      const location_array = queryParams.address_suburb.split(',')
      location_array.map((location) => {
        meta_locations.push({
          name: location,
          type: 'suburb',
          url: `?address_suburb=${location}`,
        })
      })
    }
  }

  let metaData = {
    total: total || 0,
    from: meta_from,
    end: meta_to,
    searchString: meta_type || meta_other ? `${meta_type} ${meta_other} ` : '',
    location: meta_locations,
  }

  if (agent?.title) {
    metaData.searchString += `by ${agent.title}`
  }

  return metaData
}
